/* eslint-disable max-len */
export const enUS = {
  iframe: {
    main_content: 'Main content',
    left_content: 'Left page content',
    right_content: 'Right page content',
    portrait_mode: 'Switch to portrait mode',
    album_mode_off: 'Switch to single-page view',
    album_mode_on: 'Return to double-page view',
    zoom_in_page: 'Zoom in page',
    zoom_out_page: 'Zoom out page',
    move_page_up: 'Move up',
    move_page_down: 'Move down',
    move_page_left: 'Move left',
    move_page_right: 'Move right',
    image_moved: {
      down: 'Image moved down',
      left: 'Image moved left',
      right: 'Image moved right',
      up: 'Image moved up',
    },
    reading_content: 'Reading content',
  },
  alert: {
    digital_sample_message: 'You are previewing the 2025 Release',
    invalid_releaseId_banner: 'We are having trouble loading the content. Please try again. If the issue persists, please contact ',
    invalid_releaseId_banner_link: 'Technical Support.',
  },
  annotation: {
    begin: 'Begin',
    end: 'End',
    new: 'New',
    selected: 'Selected',
    created: 'created',
    deleted: 'deleted',
    annotation: 'Annotation',
    note: 'Note',
    placemark: 'Placemark',
    'epr-shape-square-filled': 'Highlight',
    'epr-shape-square-outline': 'Box',
    'epr-shape-round-outline': 'Circle',
    'epr-shape-underline': 'Underline',
    keyboard_activate_message: 'Press Enter key to open annotation dialog. Opens in a modal.',
    keyboard_lr_message:
      'Press ENTER to access additional learning resources for this concept. Opens in a modal.',
    on_first_message: 'For quick access, all of your annotations can be found in the sidebar menu.',
    batch_delete: {
      title: 'Remove highlights, notes and placemarks',
      message_all:
        'Are you sure you want to remove all of your highlights, notes and placemarks on <b>all pages</b>? This cannot be undone.',
      message_pg:
        'Are you sure you want to remove all of your highlights, notes and placemarks on this page? This cannot be undone.',
      delete_error:
        'There was an error removing your annotations. Relaunch your book and try again.',
      success_message: {
        highlights: 'highlight successfully deleted.',
        note: 'note successfully deleted.',
        placemark: 'placemark successfully deleted.',
      },
    },
    loading_highlights: 'Loading highlights...',
    quick_annotation_message: 'You\'re in Quick Annotation mode. Select X to exit.',
    annotation_toolbar: 'annotation toolbar',
  },
  context: {
    context_menu: 'Context Menu',
    enabled: 'Enabled',
    add: 'Add',
    set_color: 'Set color {{color}}',
    highlight: {
      yellow: 'yellow',
      blue: 'blue',
      green: 'green',
      pink: 'pink',
    },
    quick_annotation: 'Quick Annotation',
    quick_annotation_info: 'With Quick Annotation mode, you can highlight, underline, box, or circle multiple selections of text.',
    add_highlight: 'Add annotation highlight',
    add_box: 'Add annotation box',
    add_circle: 'Add annotation circle',
    add_underline: 'Add annotation underline',
    add_note: 'Add Note',
    edit_note: 'Edit Note',
    save_note: 'Save Note',
    add_note_textarea: 'Add Note textarea',
    add_note_charcter_out_of: 'out of',
    add_note_charcters_remaining: 'characters used',
    add_placemark: 'Add Placemark',
    lookup_dictionary: 'Look Up In Dictionary',
    note_prompt: 'Add Note',
    read_selected: 'Read Selected',
    detached_highlight: 'This highlight no longer links to the text due to content changes.',
    detached_note: 'This note no longer links to the text due to content changes.',
    detached_placemark: 'This placemark no longer links to the text due to content changes.',
    remove_highlight: 'Remove highlight',
    remove_box: 'Remove annotation box',
    remove_circle: 'Remove annotation circle',
    remove_underline: 'Remove annotation underline',
    remove_note: 'Remove Note',
    remove_placemark: 'Remove Placemark',
    remove_annotation: 'Annotation successfully deleted.',
    remove_annotation_highlight: 'Highlight successfully deleted.',
    remove_annotation_placemark: 'Placemark successfully deleted.',
    remove_annotation_note: 'Note successfully deleted.',
    move: 'Move',
    previous: 'Previous',
    next: 'Next',
    enabled_highlight: 'Annotation highlight enabled',
    enabled_circle: 'Annotation circle enabled',
    enabled_box: 'Annotation box enabled',
    enabled_underline: 'Annotation underline enabled',
    enabled_yellow: 'Yellow enabled',
    enabled_green: 'Green enabled',
    enabled_blue: 'Blue enabled',
    enabled_pink: 'Pink enabled',
    drag_activated: 'Use arrow keys or mouse to move context menu',
    drag_deactivated: 'Toggle to activate drag and drop feature',
  },
  navigation: {
    navigation: 'Navigation',
    toolbar: 'Toolbar',
    previous: 'Previous',
    next: 'Next',
    navigation_interval: '{{lower}} of {{upper}}',
    no_title: 'No Title',
    loaded: 'loaded',
    selected_section: 'Your selected section loaded',
    reading_navigation: 'Reading',
    device_mode: 'Switch to device preview mode',
    back_button: 'Go to previous location',
    highlight_button: 'Annotate selection',
    toolkit: 'Toolkit',
    close: 'Close',
    assignment_pages: 'Assigned Pages',
    assignment_page: 'Assigned Page',
  },
  reset_activities: {
    title: 'Reset Activities',
    message_all:
      'Are you sure you want to reset all activities on all pages? This will clear all responses.',
    message_exhibit:
      'Are you sure you want to reset all activities on this page? This will clear all responses.',
    delete_error: 'There was an error resetting your activities. Relaunch your book and try again.',
  },
  overflow: {
    more: 'More',
    author_options: 'Instructor View Options',
    teacher_options: 'Teacher options',
    student_options: 'Student options',
    teacher_expanded_announce: 'Teacher options expanded',
    teacher_collapsed_announce: 'Teacher options collapsed',
    clear_highlights: 'Clear Highlights​',
    clear_widget_data_exhibit: 'Reset activities on this page',
    clear_widget_data: 'Reset all activities in this resource',
    course_style: 'Course Style',
    course_style_prompt: 'Search Styles',
    quick_annotation: 'Quick Annotation',
    save_heat_map: 'Save Highlight Heat Map',
    show_heat_map: 'Show Highlight Heat Map​',
    style_not_found: 'No matches found for "{{query}}".',
    teacher_content: 'Teacher Notes',
    highlights: {
      clear_all: 'Clear all highlights in this resource',
      clear_all_warning: 'Are you sure you want to remove all of your highlights on all pages?',
      clear_pg: 'Clear highlights​ on this page',
      clear_pg_warning: 'Are you sure you want to remove all of your highlights on this page?',
      warning_title: 'Remove highlights',
    },
  },
  shared: {
    close: 'Close',
    ok: 'OK',
    cancel: 'Cancel',
    submit: 'Submit',
    delete: 'Delete',
    with: 'with',
    and: 'and',
    accept: 'accept',
    generic_save_error: 'We were unable to save your work. Please try again.',
  },
  scrubber: {
    chapter_navigation: 'Chapter navigation',
    chapter_slider: 'Chapter slider',
    back: 'Back',
  },
  link: {
    open_external: 'Opens in a new window',
    open_modal: 'Opens in a modal',
  },
  dialog: {
    accept: 'Accept',
    are_you_sure: {
      highlight: 'Are you sure you want to delete your highlight?',
      highlight_batch: 'Are you sure you want to permanently delete these highlights/notes/placemarks? ',
      highlight_title: 'Delete Highlight',
      highlight_batch_title: 'Delete Highlights',
      note_batch_title: 'Delete Notes',
      placemark_batch_title: 'Delete Placemarks',
      note: 'Are you sure you want to delete your note?',
      notes_batch: 'Are you sure you want to permanently delete these notes?',
      note_title: 'Delete Note',
      placemark: 'Are you sure you want to delete your placemark?',
      placemarks_batch: 'Are you sure you want to permanently delete these placemarks? ',
      placemark_title: 'Delete Placemark',
      dismiss_print_file: 'Are you sure you want to dismiss this printable file?',
    },
    bookshelf_session_timeout_warning: {
      bottomtext: 'To access your book, close this tab and reopen the title from Bookshelf.',
      buttontext: 'Close tab',
      reasonlist:
        '<li>Inactivity in your reading session</li><li>Signing in to another browser</li>',
      text: 'Your reading session has ended and your book will be closed. This can be caused by:',
      title: 'Your session has timed out',
    },
    cancel: 'Cancel',
    continue: 'Continue',
    document_request_error: 'Page failed to load. Please refresh and try again. If you are using a VPN, proxy, or content blocker, please disable it and try again.',
    failed_glossary_term: 'Failed to load glossary term. Please try again later.',
    failed_message: 'Failed to load. Please try again later.',
    generating_pdf_message: 'This process may take a few moments. You may keep using your book, and we will notify you when your file is ready to print. Select OK to continue.',
    glossary_language_change: 'Glossary content updated to',
    glossary_language_label: 'Language',
    loading_message: 'Loading...',
    move_down: 'Move down',
    move_left: 'Move left',
    move_right: 'Move right',
    move_up: 'Move up',
    reset_image: 'Reset image',
    session_ended_error: 'Your session has ended. We can\'t save your changes until this book is reopened.',
    title: {
      credit: 'Credit',
      footnote: 'Footnote',
      glossary: 'Glossary',
      long_description: 'Long Description',
      wiris_editor: 'Wiris Editor',
      print: 'Print',
      generating_pdf: 'Generating PDF',
    },
    zoom_in_image: 'Zoom in image',
    zoom_out_image: 'Zoom out image',
  },
  teaching_notes: {
    answer: 'Answer',
    diff_resource: 'Differentiated Resource',
    example: 'Example',
    teach_notes: 'Teaching Notes',
  },
  contents: {
    contents: 'Contents',
    contents_dialog: 'Contents modal dialog',
    toc: 'Table of Contents',
    toc_tab_section: 'Table of Contents tab section',
    toc_go_current_location: 'Go to current location',
    toc_info: 'If using your keyboard press <br /><br />• TAB to navigate into and out of the tree<br />• DOWN ARROW and UP ARROW to navigate the tree<br />• RIGHT ARROW to expand a collapsed group<br />• LEFT ARROW to collapse an expanded group<br />• ENTER to select an item within a group',
    toc_current_location: 'Current location',
    topics_current_location: 'Current location',
    topics_back_to: 'Back to',
    topics_cases: 'Cases',
    topics_pattern_recognition: 'Pattern Recognition',
    topics_practice: 'Practice',
    topics_tip: 'Topics',
    topics_algorithms: 'Algorithms',
    topics_menu_alert:
      'Looks like something went wrong! Please refresh or try again later. If the problem persists, please contact us.',
    highlight_tab_section: 'Highlights tab section',
    note_tab_section: 'Notes tab section',
    placemark_tab_section: 'Placemark tab section',
    toc_blank: 'Table of Contents is empty or failed to load.',
    highlight_blank: "You haven't created any highlights yet.",
    highlight_session_error:
      'Highlight functionality is not available unless your session is active.',
    highlight_tip: 'Highlights',
    dropdown_tip_highlights: 'All Highlights',
    dropdown_all_highlights: 'All Highlights',
    dropdown_detached_highlights: 'Detached Highlights',
    menu_edit_highlights: 'Edit Highlights',
    menu_export_highlights: 'Export Highlights',
    dropdown_tip_notes: 'All Notes',
    dropdown_all_notes: 'All Notes',
    dropdown_detached_notes: 'Detached Notes',
    menu_edit_notes: 'Edit Notes',
    menu_export_notes: 'Export Notes',
    dropdown_tip_placemarks: 'All Placemarks',
    dropdown_all_placemarks: 'All Placemarks',
    dropdown_detached_placemarks: 'Detached Placemarks',
    menu_edit_placemarks: 'Edit Placemarks',
    menu_export_placemarks: 'Export Placemarks',
    orphan_highlight_msg: 'This highlight is detached.',
    orphan_notes_msg: 'This note is detached.',
    orphan_placemark_msg: 'This placemark is detached.',
    note_blank: "You haven't created any notes yet.",
    note_session_error: 'Notes functionality is not available unless your session is active.',
    note_tip: 'Notes',
    placemark_blank: "You haven't placed any markers yet.",
    placemark_session_error:
      'Place marker functionality is not available unless your session is active.',
    placemark_tip: 'Placemarks',
    show_more: 'Show more',
    show_less: 'Show less',
    export: 'export',
    detached_highlight: 'This highlight is detached.',
    detached_tooltip_button: 'Tooltip',
    detached_highlight_tip:
      'The text you have highlighted for this highlight no longer exists, because the book has been edited by the book publisher.',
    detached_note: 'This note is detached.',
    detached_note_tip:
      'The text you have highlighted for this note no longer exists, because the book has been edited by the book publisher.',
    detached_placemark: 'This placemark is detached.',
    detached_placemark_tip:
      'The text you have highlighted for this placemark no longer exists, because the book has been edited by the book publisher.',
  },
  search: {
    search: 'Search',
    search_dialog: 'Search modal dialog',
    display_all_results: 'Display all results',
    enter_search_term: 'Enter your search term',
    result: 'result',
    results: 'results',
    limited_results: 'Limited to 1,000 Results. Please refine your query.',
    refine_search: 'Refine your search terms and try again.',
    jump_to_page: 'Jump to ',
    text_and_page_number: 'Search for text or page number',
    result_item: 'search result item',
    loading: 'Loading...',
    loading_search: 'Search content loading',
    loading_failed: 'Search loading failed',
    try_again: 'Try again',
  },
  elastic_search: {
    topics: 'Topics',
    quizzes: 'Quizzes',
    launchable_topic: 'Launchable topic: ',
    launchable_quiz: 'Launchable quiz: ',
    search_suggestion: 'Search suggestion: ',
    breadcrumbs_separator: ', in ',
  },
  export: {
    highlights: 'Highlights',
    export_highlights: 'Export All Highlights',
    notes: 'Notes',
    export_notes: 'Export All Notes',
    success_message: 'exported successfully',
    in_progress: 'Exporting... We will notify you once completed',
    complete: 'Export complete! Ready to download.',
    download: 'Download',
    cancel: 'Cancel',
    export: 'Export',
    error_message: 'Export failed, please try later',
    of: ' of ',
    printed: 'Printed by User ',
    title_highlights: 'Highlights from',
    title_notes: 'Notes from',
  },
  readspeaker: {
    read_page: 'Read entire page',
    read_selected: 'Read selected text',
    quick_play_menu: 'Read speaker quick play menu',
    read_math: 'Read math aloud',
    open_speed_slider: 'Open speed slider',
    close_speed_slider: 'Close speed slider',
    speech_speed_slider: 'Speech speed slider',
    cant_read: 'This page can not be read.',
    error: 'Audio failed',
    play: 'Play',
    pause: 'Pause',
    mute: 'Mute',
    unmute: 'Unmute',
    volume: 'Volume',
    slow_down: 'Slow down playback speed by 25%',
    speed_up: 'Speed up playback speed by 25%',
    buffering: 'Loading',
    read_aloud: 'Read aloud',
    read_aloud_voice: 'Read aloud voice',
    progress_control: 'Progress control',
    volume_control: 'Volume control',
    speed: 'Speed',
    view_speed_control: 'Speed control',
    speed_control: 'Speed control',
    close: 'Close',
  },
  levelText: {
    level_selection: 'Level selection',
    change_level: 'Change level',
    language_selection: 'Language selection',
  },
  errorPage: {
    404: {
      title: "We can't find what you're looking for.",
      reason_list:
        '<li>The requested resource has been removed.</li><li>The wrong URL was entered.</li>',
      try_list:
        '<li custom-login>Retry the link you use to access your content.</li><li>Clear browser cache and history.</li><li>Contact your administrator.</li>',
    },
    401: {
      title: "Something went wrong and you're not able to view this content.",
      reason_list:
        '<li>Your user session has timed out.</li><li>You have incorrect credentials.</li><li>You have another book open.</li>',
      try_list:
        '<li custom-login>Retry the link you use to access your content.</li><li>Clear browser cache and history.</li><li>Contact your administrator.</li>',
      aleks: 'You do not have access to your eBook at this time. Close this window.',
    },
    403: {
      title: 'Your access to view this resource has been denied.',
      reason_list:
        "<li>You don't have access rights to this resource.</li><li>You have another book open.</li>",
      try_list:
        '<li custom-login>Retry the link you use to access your content.</li><li>Make sure you have enabled 3rd party cookies.</li><li>Remove browser cookies.</li><li>Clear browser cache and history.</li><li>Check to make sure you have access to this resource.</li>',
    },
    500: {
      title: 'Something went wrong.',
      reason_list:
        '<li>The server encountered an error launching your content.</li>',
      try_list:
        '<li custom-login>Retry the link you use to access your content.</li><li>Clear browser cache and history.</li><li>Contact McGraw Hill Support.</li>',
    },
    sessionTimeout: {
      title: 'Your session has timed out',
      text: 'Your reading session has ended and your book will be closed. Any pending work has been saved but you will need to reopen the book.',
      reason: 'This can be caused by:',
      reason_list:
        '<li>Browser tab open longer than 4 hours</li><li>Launching another book in same browser</li><li>Cross Site Tracking</li>',
      try: 'To access your book, close this tab and reopen the title.',
      tryBrowserSettings: 'Open the browser Menu, select Settings, select Privacy, deselect the check box for prevent cross-site tracking. Refresh your page and try again',
      or: 'or',
    },
    whoops: 'Whoops.',
    reason: 'The reason might be:',
    try: 'Please try the following:',
    partnerCustomer: {
      atd: {
        support:
          '<p class="title">For assistance, please contact ATD Customer Care:</p><p class="errorSubText">Call 800.628.2783 (U.S.) or +1.703.683.8100 (international), Monday through Friday, 8 a.m. - 6 p.m. ET.</p><p class="errorSubText">Speak with someone via chat at <a href="https://www.td.org" target="_blank">www.td.org</a>,<br/>Monday through Firday, 8 a.m. - 5:30 p.m. ET.</p><p class="errorSubText">Email <a href="mailto:customercare@td.org">customercare@td.org</a> anytime.</p>',
        login_again: 'Log in again at',
        login_url: 'reader.td.org',
      },
    },
  },
  banner: {
    con: {
      title: 'ReadAnywhere',
      description: 'Open the McGraw-Hill ePub Reader.',
    },
    atd: {
      title: 'ATD Reader',
      description: 'Open the ATD ePub Reader.',
    },
    open: 'Open',
    download: 'Download',
  },
  'font-resizer': {
    change_size: 'Change font size',
    increase_size: 'Increase font size',
    decrease_size: 'Decrease font size',
    current_value: 'Level {{val}}',
    control_label: 'Change font size dialog',
    slider_label: 'Font resize slider',
  },
  'concept-navigator': {
    button_previous: 'Previous concept',
    button_current: 'Skip to concept',
    button_next: 'Next concept',
  },
  large_image_navigator: 'Large Image Navigator',
  assignment: {
    submit_btn: 'Submit Assignment',
    back_assignment_btn: 'Back to Assignment',
    warning_title: 'Attention!',
    warning_content:
      'You are about to submit this assignment, locking down your work. <br> Please check that you have completed all sections.',
    warning_page_out_of_range: 'This page is not part of the assignment',
    success_title: 'Assignment Submitted',
    success_content: 'You have successfully submitted your assignment.',
    ok_btn: 'Got it!',
    error_title: 'Error',
    error_content: 'We are having trouble saving your work. Please try again later.',
    error_dialog: 'Error dialog',
    success_dialog: 'Success dialog',
    assignment_bar_submitted: 'This has been submitted. Changes will not be saved.',
    submit_k5_btn: 'Submit',
    k5_warning_content: 'Are you sure you want to submit this assignment?',
    error_missing_widget_index:
      'Assignment failed to open due to a missing index. <br> Please have your teacher contact Customer Service for support with the',
    error_reopen_failure: 'Assignment failed to open. Please retry.',
    review_mode:
      'You are in review mode. Your changes will not be saved. Highlights, placemarks and notes are disabled.',
  },
  'media-overlay': {
    audio_sync: 'Audio synchronization',
    play: 'Play',
    pause: 'Pause',
    stop: 'Stop',
  },
  widget: {
    success_title: 'Widgets cleared',
    error_title: 'Error',
    error_content: 'We are having trouble clearing your data. Please try again later.',
    success_dialog: 'Success dialog',
    error_dialog: 'Error dialog',
    ok_btn: 'Got it!',
  },
  firebase: {
    reduced_mode: "You're operating in reduced mode.",
    restored: 'Full functionality has been restored.',
  },
  language_code: {
    de: 'Deutsche',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano',
  },
  gif: {
    pause: 'Pause GIF',
    paused: 'GIF paused. Play GIF button',
    play: 'Play GIF',
    playing: 'GIF playing. Pause GIF button',
  },
  print: {
    print: 'Print',
    select: 'Choose a chapter to print:',
    piracyStatement: 'Select pages of this eBook are available for printing with considerations made to protect the intellectual property of the authors and publisher.',
    file_ready: 'Your printable file is ready:',
    error: 'There was an error retrieving your PDF. Please retry or contact support',
  },
  ai_assist: {
    button: 'AI Reader',
  },
  orphan_annotations: {
    title: 'Detached Annotations Detected!',
    paragraph_step_1: 'Some of your annotations no longer link to the text due to content changes. They will remain in your Contents menu and can be identified by an',
    paragraph_step_2: 'icon. However, they will not directly link to any content in the book.',
    do_not_show_message_again: 'Do not show this message again',
    accept_message: 'Got it',
  },
  a11y: {
    page_navigation: 'Title',
  },
};
